import axios from "~/plugins/axios";
import CartService from "~/service/cartService";

export default {
  list() {
    return axios.get("/ebsn/api/spending-card-type/list").then(data => {
      return data.data.data.paymentSpendingCards;
    });
  },

  select(paymentTypeId, select) {
    return axios
      .post("/ebsn/api/spending-card-type/select", null, {
        params: { payment_type_id: paymentTypeId, select: select }
      })
      .then(data => {
        CartService.getCart(true);
        return data.data.data.paymentSpendingCards;
      });
  },

  assignSpendingCard(paymentTypeId, data) {
    return axios
      .post("/ebsn/api/spending-card-type/assign", data, {
        params: { payment_type_id: paymentTypeId }
      })
      .then(data => {
        return data.data.data;
      });
  },
  unassignSpendingCard(paymentTypeId, spendingCardId) {
    return axios
      .post("/ebsn/api/spending-card-type/unassign", null, {
        params: {
          payment_type_id: paymentTypeId,
          spending_card_id: spendingCardId
        }
      })
      .then(data => {
        return data.data.data;
      });
  }
};
