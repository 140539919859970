import axios from "~/plugins/axios";
//import CartService from "~/service/cartService";

export default {
  detail(paymentTypeId) {
    return axios
      .get("/ebsn/api/spending-card-type/boomi-gift-card/detail", {
        params: { payment_type_id: paymentTypeId }
      })
      .then(data => {
        return data.data.data;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  },

  update(paymentTypeId, data) {
    //sull'url il parametro, nel body il json
    return axios
      .post("/ebsn/api/spending-card-type/boomi-gift-card/update-data", data, {
        params: { payment_type_id: paymentTypeId }
      })
      .then(data => {
        return data.data.data;
      });
  },

  assignSpendingCard(paymentTypeId, data) {
    return axios
      .post("/ebsn/api/spending-card-type/boomi-gift-card/assign", data, {
        params: { payment_type_id: paymentTypeId }
      })
      .then(data => {
        return data.data.data;
      });
  },

  unassignSpendingCard(paymentTypeId, spendingCardId) {
    return axios
      .post("/ebsn/api/spending-card-type/boomi-gift-card/unassign", null, {
        params: {
          payment_type_id: paymentTypeId,
          spending_card_id: spendingCardId
        }
      })
      .then(data => {
        return data.data.data;
      });
  }
};
