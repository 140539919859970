<style scoped lang="scss"></style>

<template>
  <v-list-item
    style="display: flex; justify-content: flex-start; align-items: center;"
  >
    <v-list-item-content style="flex-wrap:nowrap; min-width: 200px;">
      <v-checkbox
        v-model="spendingCard.selected"
        @change="update(spendingCard)"
        :disabled="!spendingCard.enabled"
        :key="spendingCard.code"
      />

      <div>
        <v-list-item-title>
          {{ spendingCard.codeMask }}
        </v-list-item-title>
        <v-list-item-subtitle>
          Attivazione:
          {{ $dayjs(spendingCard.activationDate).format("YYYY-MM-DD") }}
          <br />
          Scadenza:
          {{ $dayjs(spendingCard.expiredDate).format("YYYY-MM-DD") }}
          <br />
          Stato: {{ manageStatus(spendingCard.spendingCardStatusId) }}
        </v-list-item-subtitle>
      </div>
    </v-list-item-content>

    <v-list-item-content style=" min-width: 120px;">
      <v-list-item-title>
        Valore: {{ $n(spendingCard.startAmount, "currency") }}
      </v-list-item-title>
      <v-list-item-subtitle
        style="  color: rgba(0, 0, 0, 0.8);  font-weight: bold;"
      >
        Saldo: {{ $n(spendingCard.availableAmount, "currency") }}
        <br />
      </v-list-item-subtitle>
    </v-list-item-content>

    <!-- <v-list-item-content>
      <v-list-item-title>
        <br />

        <v-text-field
          v-if="spendingCard.requiredPin"
          style="width:84px;"
          color="primary"
          v-model="spendingCard.pin"
          :error-messages="errors"
          type="password"
          label="PIN"
          autocomplete="off"
          class="mb-2"
          :disabled="spendingCard.disabled"
          :required="spendingCard.requiredPin"
          dense
          outlined
          clearable
          mask="*"
          @change="updatePin(spendingCard)"
        >
        </v-text-field>
      </v-list-item-title>
    </v-list-item-content> -->

    <v-list-item-content>
      <v-list-item-title>
        Totale: {{ $n(spendingCard.currentAmount, "currency") }}
      </v-list-item-title>
    </v-list-item-content>

    <v-list-item-action>
      <v-btn
        :disabled="spendingCard.selected"
        small
        icon
        @click="unassignSpendingCard(spendingCard)"
        class="remove-card"
        aria-label="Rimuovi questa carta"
        title="Rimuovi questa carta"
      >
        <v-icon>$delete</v-icon>
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { mask } from "vue-the-mask";
import dayjs from "dayjs";

export default {
  name: "SpendingCard",
  props: {
    spendingCard: { type: Object, required: true },
    paymentTypeId: { type: Number, required: true }
  },
  mixins: [],

  directives: { mask },

  data() {
    return {
      errors: []
    };
  },

  methods: {
    async update(spendingCard) {
      console.log(spendingCard);
      this.$emit("doUpdate");
    },

    async unassignSpendingCard(spendingCard) {
      this.$emit("unassignSpendingCard", {
        idToUnassign: spendingCard.spendingCardId,
        paymentTypeId: this.paymentTypeId
      });
    },
    manageStatus(spendingCardStatusId) {
      let statusDesc = "";

      if (spendingCardStatusId == 1) {
        statusDesc = "ATTIVA";
      } else if (spendingCardStatusId == 0) {
        statusDesc = "DA ATTIVARE";
      } else if (spendingCardStatusId == 2) {
        statusDesc = "RIMOSSA";
      } else if (dayjs().isAfter(dayjs(this.spendingCard.expiredDate))) {
        statusDesc = "SCADUTA";
      }

      return statusDesc;
    }
  }
};
</script>
