<style scoped lang="scss">
.payment-type-spendingcard {
  .descr {
    font-size: 16px;
  }
}
.spendingcard-container {
  width: 100%;
}

.loading-spinner {
  /* position: absolute;*/
  position: absolute;
  margin-top: -10px;
  left: 48%;
}
.max-payable {
  margin-top: 16px;
  margin-bottom: 16px;
}
.spendingcard-data-wrapper {
  max-width: 500px;
  margin: auto;
  font-weight: 800;
  [class^="col-"],
  [class*=" col-"] {
    padding: 8px;
    text-align: center;
  }
  .row {
    border-bottom: 1px solid -var(grey-lighten2);
    &:last-child {
      border-bottom-color: transparent;
    }
  }
  .header {
    color: -var(grey-lighten1);
    font-weight: bold;
  }
  .footer-spendingcard {
    background: transparent;
    color: -var(grey-lighten1);
    font-weight: bold;
  }
}
.v-list-item__content {
  padding: 0px;
}
</style>

<template>
  <v-container
    style="position: relative;"
    class="payment-type payment-type-nexi payment-type-spendingcard px-0 px-sm-auto"
  >
    <span class="d-block descr" v-html="paymentType.descr"></span>

    <!-- v-if="loading" -->
    <v-progress-circular
      class="loading-spinner"
      v-if="loading"
      color="primary"
      size="64"
      indeterminate
    ></v-progress-circular>
    <ResponseMessage :response="response" />

    <v-row class="d-flex justify-center mt-5">
      <div class="spendingcard-container">
        <div
          class="payment-auth-list"
          v-for="spendingCardsTypeDetail in spendingCardsDetail.paymentSpendingCards"
          :key="spendingCardsTypeDetail.paymentType.paymentTypeId"
        >
          <!-- v-if="spendingCardsTypeDetail.selected"
          -->
          <div v-if="spendingCardsTypeDetail.selected">
            <v-list>
              <div
                v-if="
                  spendingCardsTypeDetail &&
                    spendingCardsTypeDetail.data &&
                    spendingCardsTypeDetail.data.spendingCards &&
                    spendingCardsTypeDetail.data.spendingCards.length > 0
                "
              >
                <div class="d-flex justify-space-between px-3">
                  <strong>Elenco carte</strong>
                  <strong>
                    Totale:
                    {{
                      $n(spendingCardsTypeDetail.data.balanceTotal, "currency")
                    }}
                  </strong>
                </div>

                <SpendingCard
                  v-for="spendingCard in spendingCardsTypeDetail.data
                    .spendingCards"
                  :key="spendingCard.code"
                  :spendingCard="spendingCard"
                  :paymentTypeId="
                    spendingCardsTypeDetail.paymentType.paymentTypeId
                  "
                  @doUpdate="doUpdate(spendingCardsTypeDetail.data)"
                  @unassignSpendingCard="unassignSpendingCard"
                />
              </div>

              <div class="d-flex justify-space-between px-3 payment-methods">
                <strong> Aggiungi nuova Carta </strong>
              </div>

              <!-- card field
               v-if="requiredPin(spendingCardsTypeDetail.paymentType)"
              -->
              <div class="col-12 d-flex" style="height: 60px;">
                <div
                  :class="
                    requiredPin(spendingCardsTypeDetail.paymentType)
                      ? 'col-7'
                      : 'col-12'
                  "
                >
                  <v-text-field
                    color="primary"
                    v-model="newSpendingCard.code"
                    :error-messages="errors"
                    type="text"
                    label="Numero Carta"
                    name="cardNumber"
                    autocomplete="off"
                    class="mb-2"
                    required
                    dense
                    outlined
                    clearable
                  >
                  </v-text-field>
                </div>

                <div
                  class="col-5"
                  v-if="requiredPin(spendingCardsTypeDetail.paymentType)"
                >
                  <v-text-field
                    color="primary"
                    v-model="newSpendingCard.pin"
                    :error-messages="errors"
                    :type="showPin ? 'text' : 'password'"
                    :append-icon="showPin ? '$eye' : '$eyeOff'"
                    @click:append="toggleShowPin"
                    autocomplete="off"
                    label="PIN"
                    name="pin"
                    class="mb-2"
                    required
                    dense
                    outlined
                    clearable
                    mask="*"
                  >
                  </v-text-field>
                </div>
              </div>

              <div class="col-12" style="margin-top: 10px;">
                <div class="d-flex flex-column remember-password">
                  <v-btn
                    :loading="loading_newcard"
                    type="submit"
                    large
                    block
                    depressed
                    class="white--text primary"
                    @click="assignSpendingCard(spendingCardsTypeDetail)"
                  >
                    {{ $t("paymentTypeList.assignSpendingCard") }}
                  </v-btn>
                  <ResponseMessage :response="response" class="mt-3" />
                </div>
              </div>
            </v-list>
          </div>
        </div>
        <br />
      </div>
    </v-row>
  </v-container>
</template>

<script>
import ResponseMessage from "@/components/ResponseMessage";
import SpendingCardService from "~/service/spendingCard/spendingCardService";
import EbsnSpendingCardService from "~/service/spendingCard/ebsnSpendingCardService";
import BoomiGiftCardService from "~/service/spendingCard/boomiGiftCardService";
import SpendingCard from "~/components/payment/SpendingCard.vue";
import gateway from "~/mixins/gateway";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "PaymentTypeGiftCard",
  mixins: [gateway],
  data() {
    return {
      loading: false,
      error: null,
      spendingCardsDetail: {},
      newSpendingCard: { code: null, pin: null },
      showPin: null,
      response: {},
      loading_newcard: false,
      dialog: false,
      valid: true,
      lazy: true,
      errors: [],
      invalid: null
    };
  },
  components: { ResponseMessage, SpendingCard },
  methods: {
    ...mapActions({
      getCart: "cart/getCart"
    }),
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated"
    }),
    goBack() {
      this.$router.push("/checkout");
    },

    async getDetail() {
      this.loading = true;
      if (this.paymentType.paymentTypeId == 29) {
        //ebsn spending card
        this.spendingCardsDetail = await EbsnSpendingCardService.detail(
          this.paymentType.paymentTypeId
        );
      } else if (this.paymentType.paymentTypeId == 28) {
        //boomi gift card
        this.spendingCardsDetail = await BoomiGiftCardService.detail(
          this.paymentType.paymentTypeId
        );
      }
      this.loading = false;
      this.getCart();
    },

    async doUpdate(spendingCardsTypeDetail) {
      this.loading = true;
      this.spendingCardsDetail = await EbsnSpendingCardService.update(
        this.paymentType.paymentTypeId,
        spendingCardsTypeDetail
      );
      this.loading = false;
      this.getCart();
    },

    async assignSpendingCard(spendingCardsTypeDetail) {
      this.loading = true;
      this.loading_newcard = true;

      if (
        (this.paymentType.paymentTypeId != 28 &&
          this.newSpendingCard.pin == null) ||
        this.newSpendingCard.code == null
      ) {
        global.EventBus.$emit("error", {
          message: global.EventBus.$t("errors.errorSpendingCard.emptyField")
        });

        this.loading = false;
        this.loading_newcard = false;
        return;
      }

      if (this.paymentType.paymentTypeId == 29) {
        //ebsn spending card
        this.spendingCardsDetail = await EbsnSpendingCardService.assignSpendingCard(
          spendingCardsTypeDetail.paymentType.paymentTypeId,
          this.newSpendingCard
        );
      } else if (this.paymentType.paymentTypeId == 28) {
        //boomi gift card
        this.spendingCardsDetail = await BoomiGiftCardService.assignSpendingCard(
          spendingCardsTypeDetail.paymentType.paymentTypeId,
          this.newSpendingCard
        );
      } else {
        //generic/base gift card
        this.spendingCardsDetail = await SpendingCardService.assignSpendingCard(
          spendingCardsTypeDetail.paymentType.paymentTypeId,
          this.newSpendingCard
        );
      }

      this.resetAddSpendingCardField();
      this.getCart();

      this.loading_newcard = false;
      this.loading = false;
    },

    async unassignSpendingCard(scToUnassign) {
      if (this.paymentType.paymentTypeId == 29) {
        this.spendingCardsDetail = await EbsnSpendingCardService.unassignSpendingCard(
          scToUnassign.paymentTypeId,
          scToUnassign.idToUnassign
        );
      } else {
        this.spendingCardsDetail = await SpendingCardService.unassignSpendingCard(
          scToUnassign.paymentTypeId,
          scToUnassign.idToUnassign
        );
      }

      this.getCart();
    },

    resetAddSpendingCardField() {
      this.newSpendingCard = { code: null, pin: null };
    },

    toggleShowPin() {
      this.showPin = !this.showPin;
    },

    requiredPin(paymentType) {
      if (paymentType.paymentTypeId == 29) {
        return true;
      } else if (paymentType.paymentTypeId == 28) {
        return false;
      } else {
        return true;
      }
    }
  },

  mounted() {
    global.EventBus.$on("closeDialog", () => {
      this.dialog = false;
    });
  },

  created() {
    this.getDetail();
  },

  watch: {
    selected(newValue, oldValue) {
      if (newValue != oldValue && newValue == true) {
        this.getDetail();
      }
    }
  }
};
</script>
